<template>
  <v-card
    elevation="0"
    :loading="loaders.loading"
    class="mx-auto "
    style=""
    max-width="1200"
  >
    <v-img :src="participant.cover"
           max-height="300"
           :lazy-src="require(`@/assets/images/avatars/logo.png`)"
           gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)"
           class="white--text align-end"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0 "
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
      <v-card-title class="pa-1" v-if="participant.id" >
        <v-avatar
          size="50"
          class="ma-0 mr-3 av-active"
        >
          <v-img
            v-if="participant.cover"
            alt="Avatar"
            contain
            :src="require(`@/assets/images/avatars/${participant.user.avatar}`)"
          />
          <v-img
            v-else
            alt="Avatar"
            contain
            :src="require(`@/assets/images/avatars/logo.png`)"
          />
        </v-avatar>
        {{participant.user.username}}
      </v-card-title>
      <v-card-actions v-if="participant.id" >
        {{participant.title}}
        <v-spacer></v-spacer>
        <v-btn color="primary" :to="'/scan/one-shot/'+participant.chapter.id" >Lire le Oneshot</v-btn>
      </v-card-actions>
    </v-img>

    <v-card-title class="d-flex py-7 position-relative">
      Synopsis
    </v-card-title>
    <v-card-text>
      {{participant.synopsis}}
    </v-card-text>
    <template  v-if="participant.id" class="">
      <!-- logo -->
      <v-card-title class="d-flex py-7 position-relative">
        Extrait ({{participant.chapter.nbpage}})
      </v-card-title>

        <v-row dense >
          <v-col
            v-for="(item, n) in participant.pages"
            :key="n"
            class="d-flex child-flex"
            cols="4"
          >
            <v-img
              :src="item.src"
              :lazy-src="require('@/assets/images/holder.jpg')"
              contain
              class="grey lighten-2 align-end"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>

    </template>

    <template v-if="participant.id && participant.user.id == user.id" >

      <v-toolbar
        color="cyan"
        flat
      >
        <!--          <v-app-bar-nav-icon></v-app-bar-nav-icon>-->

        <v-toolbar-title>Modifier</v-toolbar-title>

        <v-spacer></v-spacer>

        <!--      <v-btn
                     color="primary"
                     @click="replyPost"
              >
                Annuler ma participation
              </v-btn>-->

      </v-toolbar>

      <v-card  flat>

        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              step="1"
              @click="e1 = 1"
            >
              Informations Detaillees
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="2"
              @click="e1 = 2"
            >
              Charger les Images (max 1Mo/images)
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="pa-0 pa-md-3 pa-lg-6">
            <v-stepper-content  class="pa-0 pa-md-3 pa-lg-6" step="1">
              <v-card-text  >
                <v-alert
                  color="blue"
                  type="warning"
                >
                  Renseignez les informations relatives à votre travail et enregistrez. Vous pourrez
                  les
                  modifier a tout moment tant que le concours est toujours en cours.
                </v-alert>

                <v-alert
                  color="primary"
                  type="warning"
                >
                  Envoyer le one shot par mail a l'adresse editorial.3ag@gmail.com ou vous pouvez
                  deposer a notre studio 3ag face lycee de makepe
                </v-alert>
              </v-card-text>

              <v-card-text  >
                <v-text-field dense outlined v-model="participant.title" label="Titre"></v-text-field>
                <v-textarea dense outlined v-model="participant.synopsis" label="Synopsis"></v-textarea>
                <v-file-input dense outlined v-model="cover" label="Choisir une image de couverture"
                ></v-file-input>
                <template v-if="participant.cover">
                  <v-img class="mt-6" contain height="300" :src="participant.cover"></v-img>
                </template>

              </v-card-text>

              <v-card-actions >
                <v-btn color="primary" :loading="loaders.update" @click="updateWork">
                  Enregistrez les modifications
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn   @click="e1 = 2">
                  Charger les pages
                </v-btn>
              </v-card-actions>

            </v-stepper-content>
            <v-stepper-content  class="pa-0 pa-md-3 pa-lg-6" step="2">

              <v-card-text class="pa-0 pa-md-3 pa-lg-6" >
                <v-alert
                  color="blue"
                  type="warning"
                >
                  Dimension pour les pages la largeur doit etre comprise entre 800px et 1200px
                  pour les doubles pages entre 1500 et 1800 pour un chargement optimal
                </v-alert>

                <chapter-form-component v-if="participant.id"
                                        :chapter="participant.chapter"
                ></chapter-form-component>

              </v-card-text>

              <v-card-actions >
                <v-btn   @click="e1 = 1">
                  Retour
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>


        <v-alert v-if="participant.status" color="success"
                 type="success"
        >
          Votre travail a bien ete envoye Merci pour votre participation et bonne chance :D
        </v-alert>
        <v-card-actions class="mt-3" >
          <v-btn color="primary" v-if="participant.id" :loading="loaders.update"
                 block @click="submitWork"
          >
            Soumettre mon travail
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="dialog.submitted"
        max-width="600"
      >
        <v-card
          color="success"
          elevation="0"
        >
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              text
              @click="dialog.submitted = false"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title>BRAVO!! Votre travail a bien ete soumis.</v-card-title>
          <v-card-text>Vous venez de soumettre votre projet l'equipe en charge des évaluations
            commencera la lecture et la notation
          </v-card-text>
          <v-card-text>Vous recevrez un email ou un SMS avec le lien de lecture en ligne de
            votre
            travail.
            Invitez vos amis a creer leur compte sur la plateforme, a liker et commenter votre
            travail. le vote du publique comptera pour 40%
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              text
              @click="dialog.snack = false"
            >
              Fermer
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

    </template>

  </v-card>
</template>

<script>
import {
  mdiFacebook,
  mdiChevronLeft,
  mdiClose,
} from '@mdi/js'
import { useRouter } from '@/utils'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import LoginComponent from '@/components/LoginComponent'
import CommentComponent from '@/components/CommentComponent'
import PostReplyFormComponent from '@/components/PostReplyFormComponent.vue'
import PostFormComponent from '@/components/PostFormComponent.vue'
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";

export default {
  name: 'Contest',
  components: {
    ChapterFormComponent,
    PostFormComponent,
    PostReplyFormComponent,
    CommentComponent,
    LoginComponent
  },
  setup() {

    const user_id = ref(localStorage.getItem('user_id'))
    const { router } = useRouter()
    const route = router.currentRoute
    const { id } = route.params
    const user = Drequest.getUser()
    const loaders = ref({
      loading: true,
      enroll: false,
      update: false,
      replypost: false,
      cardload: true,
    })
    const dialog = ref({
      authentication: false,
      submitted: false,
      newenroll: false,
    })
    const post = ref({})
    const contest = ref({ cmstext: {}, post:{postimages:[]} })
    const participant = ref({})
    const participants = ref([])
    const postcategories = ref([])
    const participation = ref({})
    const cover = ref(null)
    const panel = ref(0)
    const e1 = ref(1)

    var url = 'detail.participant?jsonmodel=detail&id=' + id

    Drequest.api(url)
      .get(response => {
        console.log(response)
        contest.value = response.participant.contest
        participant.value = response.participant
        loaders.value.loading = false
      })

    const submitWork = () => {

      if (!confirm('En soumettant votre travail vous ne pourrez plus modifier. Est ce que vous confirmez la soumission?')) {
        return 0
      }
      updateWork(1)
    }

    const updateWork = (status) => {
      if (!participant.value.title) {
        alert('Vous devez au moins renseigner le titre!')
        return 0
      }
      var fd = new FormData()
      fd.append('title', participant.value.title)
      fd.append('synopsis', participant.value.synopsis)
      if (status) {
        fd.append('status', 1)
      }

      if (cover.value) {
        console.log(cover.value)
        fd.append('cover', cover.value)
      }

      loaders.value.update = true
      Drequest.api('participant.update?id=' + participant.value.id)
        .data(fd)
        .post((response) => {
          loaders.value.update = false
          console.log(response)
          participant.value = response.participant
          cover.value = null
          if (response.participant.status) {
            dialog.value.submitted = true
          }
        })
    }


    const posted = post => {
      console.log(post)
      loaders.value.replypost = false
      //children.value.unshift(post)
      alert('Post publie')
    }
    const replyPost = () => {
      console.log(contest.value.post)
      post.value = contest.value.post
      loaders.value.replypost = true
    }
    const cancelpost = () => {
      loaders.value.replypost = false
    }
    return {
      updateWork,
      submitWork,
      posted,
      replyPost,
      cancelpost,

      user_id,
      user,
      post,
      contest,
      participation,
      participant,
      participants,
      cover,
      loaders,
      dialog,
      panel,
      e1,
      icons: {
        mdiClose,
        mdiChevronLeft,
      }
    }
  }
}
</script>

<style scoped>

</style>
